<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="addModel-container"
  >
    <div class="title">日报邮件设置</div>
    <div class="grey-line"></div>
    <div class="addModel-main">
      <div class="main-title">新建邮件模板</div>
      <el-form
        class="form-container"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="160px"
      >
        <el-form-item label="邮件模板名称：" prop="modelName">
          <el-input
            class="form-input"
            placeholder="请输入邮件模板名称"
            v-model="ruleForm.modelName"
          ></el-input>
          <span class="form-tip">*邮件模板名称为邮件主题，例：（模板名称）每日客服工作汇报11-12</span>
        </el-form-item>
        <el-form-item label="选择关联客户：" prop="customerId">
          <el-select
            class="form-input"
            v-model="ruleForm.customerId"
            placeholder="请输入（可搜索历史客户）"
            @change="
              (val) => {
                changeCust(val);
              }
            "
            clearable
            filterable
            remote
            :remote-method="
              (val) => {
                remoteMethod(val);
              }
            "
          >
            <el-option
              v-for="item in custSelect"
              :key="item.id"
              :label="item.nickName"
              :value="item.id"
            ></el-option>
          </el-select>
          <span>
            <!-- <el-button class="common-screen-btn" type="primary" @click="() => {  }">查询</el-button> -->
            <el-button class="common-screen-btn" plain @click="() => { resetCustomer() }">重置</el-button>
          </span>
          <div v-if="orderList && orderList.length">
            <el-table
              class="common-table"
              style="width: 98%;margin-left: 0"
              border
              :data="orderList"
            >
              <el-table-column
                prop="orderNo"
                label="订单编号"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="createTime"
                label="订单创建时间"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="serviceTime"
                label="订单服务时间"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="shopNum"
                label="约定服务店铺数"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="dayNum"
                label="协议单账号日均咨询量"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="dayServed"
                label="已服务天数"
                :formatter="tableColumn"
              ></el-table-column>
              <el-table-column
                prop="remaininDay"
                label="剩余服务天数"
                :formatter="tableColumn"
              ></el-table-column>
            </el-table>
          </div>
        </el-form-item>
        <el-form-item
          label="对接信息设置："
          :rules="[{ required: true, message: ' ', trigger: 'change' }]"
        >
          <el-table
            class="common-table"
            style="width: 98%;margin: 0"
            border
            :data="ruleForm.infoSetList"
          >
            <el-table-column
              prop="name"
              label="服务对接角色"
              :formatter="tableColumn"
            ></el-table-column>
            <el-table-column label="对接名称" :formatter="tableColumn">
              <template slot-scope="scope">
                <div v-if="scope.row.name == '项目负责人'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].projectLeaderName`"
                  >
                    <el-input
                      placeholder="请输入对接人姓名"
                      v-model.trim="scope.row.projectLeaderName"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '客服经理'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].serviceManagerName`"
                  >
                    <el-input
                      placeholder="请输入对接人姓名"
                      v-model.trim="scope.row.serviceManagerName"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '客服组长'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].teamLeaderName`"
                  >
                    <el-input
                      placeholder="请输入对接人姓名"
                      v-model.trim="scope.row.teamLeaderName"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '质检人员'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].qualityInspectionName`"
                  >
                    <el-input
                      placeholder="请输入对接人姓名"
                      v-model.trim="scope.row.qualityInspectionName"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '对接群'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].grop`"
                  >
                    <el-input
                      placeholder="请输入对接群名称"
                      v-model.trim="scope.row.grop"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
            <el-table-column label="联系方式" :formatter="tableColumn">
              <template slot-scope="scope">
                <div v-if="scope.row.name == '项目负责人'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].projectLeaderTel`"
                  >
                    <el-input
                      placeholder="请输入对接人联系方式"
                      v-model.trim="scope.row.projectLeaderTel"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '客服经理'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].serviceManagerTel`"
                  >
                    <el-input
                      placeholder="请输入对接人联系方式"
                      v-model.trim="scope.row.serviceManagerTel"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '客服组长'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].teamLeaderTel`"
                  >
                    <el-input
                      placeholder="请输入对接人联系方式"
                      v-model.trim="scope.row.teamLeaderTel"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '质检人员'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].qualityInspectionTel`"
                  >
                    <el-input
                      placeholder="请输入对接人联系方式"
                      v-model.trim="scope.row.qualityInspectionTel"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else-if="scope.row.name == '官方热线'">
                  <el-form-item
                    :rules="[
                      { required: true, message: ' ', trigger: 'change' }
                    ]"
                    :prop="`infoSetList[${scope.$index}].officialPhone`"
                  >
                    <el-input
                      placeholder="请输入官方热线号码"
                      v-model.trim="scope.row.officialPhone"
                    ></el-input>
                  </el-form-item>
                </div>
                <div v-else>--</div>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item
          label="发件人设置:"
          :rules="[{ required: true, message: ' ', trigger: 'change' }]"
        >
          <el-table
            class="common-table"
            style="width: 98%;margin: 0"
            border
            :data="ruleForm.SenderList"
          >
            <el-table-column label="发件人昵称" :formatter="tableColumn">
              <template slot-scope="scope">
                <el-form-item
                  :rules="[{ required: true, message: ' ', trigger: 'change' }]"
                  :prop="`SenderList[${scope.$index}].nickName`"
                >
                  <el-input
                    placeholder="请输入发件人昵称"
                    v-model.trim="scope.row.nickName"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="发件人邮箱" :formatter="tableColumn">
              <template slot-scope="scope">
                <el-form-item
                  :rules="[{ required: true, message: ' ', trigger: 'change' },{ type: 'email', message: ' ', trigger: 'change' }]"
                  :prop="`SenderList[${scope.$index}].fromEmail`"
                >
                  <el-input
                    placeholder="请输入发件人邮箱"
                    v-model.trim="scope.row.fromEmail"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="发件人邮箱密码" :formatter="tableColumn">
              <template slot-scope="scope">
                <el-form-item
                  :rules="[{ required: true, message: ' ', trigger: 'change' }]"
                  :prop="`SenderList[${scope.$index}].password`"
                >
                  <el-input
                    placeholder="请输入发件人邮箱密码"
                    v-model.trim="scope.row.password"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="发件人邮箱授权码" :formatter="tableColumn">
              <template slot="header">
                <span>
                  <span>发件人邮箱授权码</span>
                  <span
                    class="table-tip"
                    @click="
                      () => {
                        openFile();
                      }
                    "
                    ><i class="el-icon-question"></i>如何获取授权码</span
                  >
                </span>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  :rules="[{ required: true, message: ' ', trigger: 'change' }]"
                  :prop="`SenderList[${scope.$index}].code`"
                >
                  <el-input
                    placeholder="请输入发件人邮箱授权码"
                    v-model.trim="scope.row.code"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="收件人邮箱：" prop="toEmail">
          <el-input
            class="form-input"
            placeholder="收件人邮箱"
            v-model="ruleForm.toEmail"
          ></el-input>
        </el-form-item>
        <el-form-item label="抄送人邮箱：">
          <el-form-item
            v-for="(item, index) in ruleForm.ccEmailArr"
            :key="index"
            :rules="[{ type: 'email', message: ' ', trigger: 'change' }]"
            :prop="`ccEmailArr[${index}]`"
          >
            <!-- <div> -->
            <el-input
              class="form-input"
              placeholder="抄送人邮箱"
              v-model="ruleForm.ccEmailArr[index]"
            ></el-input>
            <i
              class="el-icon-circle-close"
              v-if="ruleForm.ccEmailArr.length != 1"
              @click="
                () => {
                  deleteCcEmail(index);
                }
              "
            ></i>
            <i
              class="el-icon-circle-plus-outline"
              v-if="index == ruleForm.ccEmailArr.length - 1"
              @click="
                () => {
                  addCcEmail();
                }
              "
            ></i>
            <!-- </div> -->
          </el-form-item>
        </el-form-item>
        <el-form-item label="邮件结束语设置：" prop="conte">
          <quill-editor
            class="editor"
            :options="editorOption"
            v-model="ruleForm.conte"
          ></quill-editor>
        </el-form-item>
      </el-form>
    </div>
    <div class="grey-line"></div>
    <div class="addModel-bottom">
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            saveCommit();
          }
        "
        >保存</el-button
      >
      <el-button
        class="common-screen-btn"
        plain
        @click="
          () => {
            $router.push('/mail/setting');
          }
        "
        >返回</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  customerSelect,
  addEmailMod,
  emailModDetail,
  editEmailMod
} from "../../service/dataInfo.js";
import { tableColumn, duplicateRemoval, Config } from "../../utils/index.js";
import { quillEditor } from "vue-quill-editor";
import Quill from "quill";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageResize", ImageResize);
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  ["clean"], // 清除文本格式-----['clean']
  ["image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
];
export default {
  components: { quillEditor },
  data() {
    return {
      tableColumn,
      loading: false,
      modelId: this.$route.query.id, // 编辑-模板id
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      custList: [], // 客户搜索列表
      orderList: [], // 订单列表
      custSelect: [], // 客户下拉
      ruleForm: {
        modelName: "", // 模板名称
        ccEmailArr: [""], // 抄送人邮箱
        customerId: "", // 客户id
        toEmail: "", // 收件人邮箱
        conte: "", // 尾部信息-富文本
        SenderList: [
          // 发件人设置
          { nickName: "", fromEmail: "", password: "", code: "" }
        ],
        infoSetList: [
          // 对接信息设置
          { name: "项目负责人", projectLeaderName: "", projectLeaderTel: "" },
          { name: "客服经理", serviceManagerName: "", serviceManagerTel: "" },
          { name: "客服组长", teamLeaderName: "", teamLeaderTel: "" },
          {
            name: "质检人员",
            qualityInspectionName: "",
            qualityInspectionTel: ""
          },
          { name: "对接群", grop: "" },
          { name: "官方热线", officialPhone: "" }
        ]
      },
      rules: {
        // 校验
        modelName: [
          { required: true, message: "请输入模板名称", trigger: "change" }
        ],
        customerId: [
          { required: true, message: "请选择关联客户", trigger: "change" }
        ],
        toEmail: [
          { required: true, message: "请填写邮箱地址", trigger: "change" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "change" }
        ],
        conte: [{ required: true, message: "请填写内容", trigger: "change" }]
      },
      editorOption: {
        //  富文本编辑器配置
        theme: "snow",
        placeholder: "请输入正文",
        modules: {
          // imageDrop: true,
          imageResize: {
            //添加
            displayStyles: {
              //添加
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"] //添加
          },
          //  富文本编辑器配置
          toolbar: {
            container: toolbarOptions
          }
        }
      }
    };
  },
  created() {
    if (this.modelId) {
      this.emailModDetail(this.modelId);
    }
  },
  methods: {
    resetCustomer () {
      this.ruleForm.customerId = ''
      this.orderList = []
      this.custSelect = []
    },
    async emailModDetail(modelId) {
      this.loading = true;
      let { data } = await emailModDetail({ id: modelId });
      this.loading = false;
      if (data) {
        if (data.customerName) {
          this.remoteMethod(data.customerName).then(() => {
            this.changeCust(data.customerId);
          });
        }
        let ruleForm = {
          modelName: data.modelName, // 模板名称
          companyId: data.companyId,
          ccEmailArr: data.ccEmail ? data.ccEmail.split(",") : [""], // 抄送人邮箱
          customerId: data.customerId, // 客户id
          toEmail: data.toEmail, // 收件人邮箱
          conte: data.conte, // 尾部信息-富文本
          SenderList: [
            // 发件人设置
            {
              nickName: data.nickName,
              fromEmail: data.fromEmail,
              password: data.password,
              code: data.code
            }
          ],
          infoSetList: [
            // 对接信息设置
            {
              name: "项目负责人",
              projectLeaderName: data.projectLeaderName || "",
              projectLeaderTel: data.projectLeaderTel || ""
            },
            {
              name: "客服经理",
              serviceManagerName: data.serviceManagerName || "",
              serviceManagerTel: data.serviceManagerTel || ""
            },
            {
              name: "客服组长",
              teamLeaderName: data.teamLeaderName || "",
              teamLeaderTel: data.teamLeaderTel || ""
            },
            {
              name: "质检人员",
              qualityInspectionName: data.qualityInspectionName || "",
              qualityInspectionTel: data.qualityInspectionTel || ""
            },
            { name: "对接群", grop: data.grop || "" },
            { name: "官方热线", officialPhone: data.officialPhone || "" }
          ]
        };
        this.ruleForm = ruleForm;
      }
    },
    async saveCommit() {
      // 添加模板提交
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          // 发送请求
          let ruleForm = { ...this.ruleForm };
          let newRuleForm = {};
          if (ruleForm.infoSetList && ruleForm.infoSetList.length) {
            ruleForm.infoSetList.map((item) => {
              newRuleForm = Object.assign(newRuleForm, item);
            });
            delete newRuleForm.name;
          }
          if (ruleForm.SenderList && ruleForm.SenderList.length) {
            ruleForm.SenderList.map((item) => {
              newRuleForm = Object.assign(newRuleForm, item);
            });
          }
          ruleForm.ccEmail = ruleForm.ccEmailArr.join(",");
          ruleForm = Object.assign(ruleForm, newRuleForm);
          delete ruleForm.infoSetList;
          delete ruleForm.SenderList;
          delete ruleForm.ccEmailArr;
          let code;
          this.loading = true;
          if (this.modelId) {
            ruleForm.id = this.modelId;
            code = (await editEmailMod(ruleForm)).code;
          } else {
            code = (await addEmailMod(ruleForm)).code;
          }
          this.loading = false;
          if (code == 200) {
            this.$message.success("保存成功");
            this.$router.push("/mail/setting");
          }
        } else {
          this.$message.error("请填写完整的信息");
        }
      });
    },
    deleteCcEmail(i) {
      // 删除抄送人
      let ruleForm = { ...this.ruleForm };
      if (ruleForm.ccEmailArr && ruleForm.ccEmailArr.length) {
        ruleForm.ccEmailArr = ruleForm.ccEmailArr.filter((item, index) => {
          return i != index;
        });
      }
      this.ruleForm = ruleForm;
    },
    addCcEmail() {
      let ruleForm = { ...this.ruleForm };
      if (ruleForm.ccEmailArr && ruleForm.ccEmailArr.length) {
        ruleForm.ccEmailArr.push("");
      }
      this.ruleForm = ruleForm;
    },
    openFile() {
      window.open(
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E8%BD%AF%E4%BB%B6%E5%8C%85/%E6%8E%88%E6%9D%83%E7%A0%81%E8%8E%B7%E5%8F%96.mp4"
      );
    },
    changeCust(val) {
      this.orderList = this.custList.filter((item) => {
        return item.id == val;
      });
    },
    async remoteMethod(val) {
      let { data } = await customerSelect({ nickName: val, companyId: this.companyId });
      let newArr = [];
      if (data && data.length) {
        data.map((item) => {
          newArr.push({ id: item.id, nickName: item.nickName });
        });
      }
      newArr = duplicateRemoval(newArr, "id");
      this.custSelect = newArr || [];
      this.custList = data || [];
    },
    rowClick(row) {
      console.log(row, "--------------------");
    }
  }
};
</script>
<style lang="less" scoped>
.addModel-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  .grey-line {
    height: 16px;
    width: 120%;
    margin: 0 0 0 -10%;
    background: #eee;
  }
  .el-icon-circle-plus-outline,
  .el-icon-circle-close {
    color: #333;
    margin-left: 16px;
    font-size: 20px;
    cursor: pointer;
  }
  .el-icon-question {
    color: #b3b3b3;
    margin: 0 8px 0 16px;
  }
  .table-tip {
    color: #1890ff;
    cursor: pointer;
    font-size: 12px;
  }
  /deep/.el-table--border th {
    background: #f2f4f5;
    padding: 0;
  }
  .form-container {
    width: 100%;
    margin: 32px 0;
  }
  .form-tip {
    font-size: 12px;
    color: #999;
    margin-left: 16px;
  }
  .form-input {
    width: 400px;
  }
  .addModel-bottom {
    height: 64px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 16px;
  }
  .addModel-main {
    flex: 1;
    overflow: auto;
    overflow-x: hidden;
  }
  .main-title {
    box-sizing: border-box;
    padding: 16px;
    height: 68px;
    color: #101010;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &::after {
      display: flex;
      content: " ";
      position: absolute;
      height: 1px;
      width: 120%;
      background: #f2f4f5;
      bottom: 0;
      left: -10%;
    }
  }
  .title {
    box-sizing: border-box;
    padding: 6px 16px 22px;
    height: 56px;
    color: #333;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    position: relative;
    &::after {
      display: flex;
      content: " ";
      position: absolute;
      height: 1px;
      width: 120%;
      background: #f2f4f5;
      bottom: 0;
      left: -10%;
    }
  }
}
</style>
<style lang="less">
.editor {
  line-height: normal !important;
  width: 98%;
  height: 400px;
  padding-bottom: 48px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
